import React from 'react'
import { Steps } from 'antd'

export default function StepSaleMobile(props) {
    return (
        <Steps
            current={props.stepId}
            items={[
                {
                    title: 'เลือกโทรศัพท์ประเมินราคา'
                },
                {
                    title: 'ระบุข้อบกพร่อง เเละรอยตำหนิ'
                },
                {
                    title: 'เลือกสถานที่รับซื้อ'
                }
            ]}
        />
    )
}
