import { FloatButton } from 'antd'
import React, { useState } from 'react'
import { Icon } from '@iconify/react'

export default function FloatButtons() {

    const [open, setOpen] = useState(true)

    return (
        <>
            <FloatButton.Group
                trigger="click"
                type="primary"
                open={open}
                onOpenChange={(e) => {
                    setOpen(e)
                }}
                style={{
                    insetInlineEnd: 24,
                }}
                icon={<Icon icon="fa6-brands:facebook-messenger" style={{ color: "white", width: 21, height: 21 }} />}
            >
                {/* <FloatButton
                    icon={<Icon icon="fa6-brands:facebook-messenger" style={{ color: "#FF9900", width: 21, height: 21 }} />}
                    tooltip="kingdomstore888"
                    onClick={() => {
                        window.open("https://www.messenger.com/t/kingdomstore888")
                    }}
                /> */}

                <FloatButton
                    icon={<Icon icon="bi:line" style={{ color: "#FF9900", width: 21, height: 21 }} />}
                    tooltip="@kingdomstore"
                    onClick={() => {
                        window.open("https://page.line.me/kingdomstore?openQrModal=true")
                    }}
                />

                <FloatButton
                    icon={<Icon icon="mingcute:phone-fill" style={{ color: "#FF9900", width: 21, height: 21 }} />}
                    tooltip="090-924-3388"
                    onClick={() => {
                        window.open("tel:+66909243388")
                    }}
                />

                {/* <FloatButton
                    icon={<Icon icon="clarity:email-solid" style={{ color: "#FF9900", width: 21, height: 21 }} />}
                    tooltip="kingdomstore888@gmail.com"
                    onClick={() => {
                        window.open("mailto:kingdomstore888@gmail.com")
                    }}
                /> */}
            </FloatButton.Group>
        </>
    )
}
