/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay, A11y } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
// import 'swiper/css/scrollbar'
import "./css/index.css"
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import { Carousel } from "antd"

export default function CorousBanner(props) {
    // console.log("props.data : ", props.data)
    const { width } = useDimensions()

    const onChange = (currentSlide) => {
        // console.log(currentSlide)
    }

    // const sizeCarousel = () => {
    //     switch (width) {
    //         case 320:

    //             break;

    //         default:
    //             break;
    //     }
    // }

    return (
        <div>
            {props.data?.length > 0 ?
                <center>
                    <Carousel
                        autoplay
                        autoplaySpeed={5000}
                        afterChange={onChange}
                        style={{ width: width >= 1280 ? "100%" : (width >= 768 ? 400 : 300) }}
                    >
                        {props.data.map((val, index) =>

                            <img
                                src={`${val?.BannerImage?.googleImage}`}
                                style={{
                                    width: "100%", // width >= 768 ? "100%" : "auto",
                                    height: 10
                                }}
                            />

                        )}
                    </Carousel>
                </center>
                : []
            }
        </div>
    )
}