/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm,
    Select,
    Upload
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import { baseApi, server } from '../../../../constants'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { deleteReviewByIdFetch, getReviewFetch, insertReviewFetch, updateReviewFetch } from './API/reviewApi'

const { TextArea } = Input;
const { Option } = Select

// Column Table
const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'รายการรีวิว',
        dataIndex: 'title',
        width: "25%",
    },
    {
        title: 'keyword',
        dataIndex: 'keyword',
        width: "10%",
    },
    {
        title: 'รูปภาพ',
        dataIndex: 'image',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    <img src={record.image} alt={record.title} style={{ width: 100 }} />,
                </>
            )
        }
    },
    {
        title: 'สถานะ',
        width: "15%",
        render: (text, record) => {
            return (
                <>
                    {record.isActive ?
                        <label>เปิดใช้งาน</label>
                        :
                        <label>ปิดใช้งาน</label>
                    }
                </>
            )
        }
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'เเก้ไขล่าสุด',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "10%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function ReviewManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [formReview] = Form.useForm()
    const [formSearch] = Form.useForm()

    const accessToken = sessionStorage.getItem("accessToken")

    const [imageReviewURL, setImageReviewURL] = useState({
        loading: false,
        imageUrl: null
    })

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const [modalReview, setModalReview] = useState({
        isShow: false,
        title: null
    })

    const optionReviewImage = {
        name: 'file',
        action: `${baseApi}${server.UPLOAD_FILE}?type=review`,
        data: {
            name: "admin",
        },
        headers: {
            'x-access-token': accessToken
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                let result = info.file.response
                if (result?.isSuccess) {
                    setImageReviewURL({
                        imageUrl: result.formData.fileUrl,
                        loading: false
                    })
                }
            } else {
                setImageReviewURL({
                    imageUrl: imageReviewURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const getReviewAll = async (title) => {
        setLoading(true)

        let param = {
            title,
            isActive: "",
            page: pageCurrentRef.current,
            size: pageSize
        }
        const result = await getReviewFetch(param, null, accessToken)
        setTotal(result?.totalItems)
        let tempList = []
        result?.results?.map((val, index) => {
            tempList.push({
                index: (pageCurrentRef.current * pageSize-10) + (index + 1),
                title: val.title,
                keyword: val.keyword,
                image: val.image,
                isActive: val.isActive,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={async () => {
                                formReview.setFieldsValue({
                                    id: val.id,
                                    title: val.title,
                                    keyword: val.keyword,
                                    isActive: val.isActive
                                })
                                setImageReviewURL({
                                    loading: false,
                                    imageUrl: val.image
                                })

                                setModalReview({ isShow: true, title: "edit" })
                            }}
                        >
                            <div style={{ marginTop: 0, marginLeft: -8 }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 20, height: 20 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleReviewDelete(val.id)

                                // reload
                                await getReviewAll("")
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            >
                                <div style={{ marginTop: 0, marginLeft: -8 }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 20, height: 20 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            })
        })
        setList(tempList)
        searchNameRef.current = title

        setLoading(false)
    }

    const onReviewFinish = async (values) => {
        let param = {
            id: values.id ? values.id : ""
        }

        let body = {
            "title": values.title ? values.title : "",
            "image": imageReviewURL.imageUrl ? imageReviewURL.imageUrl : "",
            "keyword": values.keyword ? values.keyword : "",
            "isActive": values.isActive ? 1 : 0,
        }

        if (modalReview.title === "add") {
            const result = await insertReviewFetch(null, body, accessToken)
            if (result && result.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modalReview.title === "edit") {
            const result = await updateReviewFetch(param, body, accessToken)
            if (result && result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getReviewAll("")

        // set default
        setFormReviewDefault()
    }

    const onSearchFinish = async (values) => {
        let title = values?.title ? values.title : ""
        await getReviewAll(title)
    }

    const handleReviewDelete = async (id) => {
        let param = {
            id
        }
        const result = await deleteReviewByIdFetch(param, null, accessToken)
        if (result && result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        pageCurrentRef.current = n.current
        getReviewAll(searchNameRef.current)
    }

    const setFormReviewDefault = () => {
        formReview.setFieldsValue({
            title: undefined,
            keyword: undefined,
            isActive: undefined
        })

        setImageReviewURL({
            loading: false,
            imageUrl: null
        })

        setModalReview({
            isShow: false,
            title: null
        })
    }

    const getBaseApi = async () => {
        getReviewAll("")
    }
    
    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการรีวิว</label>
            </Col>

            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                // label="ชื่ออสังหา"
                                name="title"
                                style={{ width: '100%' }}
                            >
                                <Input placeholder="ค้นหาชื่อรีวิว" />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: -24 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        formReview.resetFields()

                        setModalReview({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่มรายการ</Button>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrentRef.current,
                        pageSize: pageSize,
                        total: total
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modalReview.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modalReview.isShow}
                zIndex={999}
                onCancel={() => {

                    // default
                    setFormReviewDefault()
                }}
                width={700}
                onOk={() => {
                    formReview.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={formReview}
                    onFinish={onReviewFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="id" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="title"
                                label="ชื่อรีวิว"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อรีวิว' }]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 1, maxRows: 2 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="keyword"
                                label="Keyword"
                                rules={[{ required: true, message: 'กรุณากรอก Keyword' }]}
                            >   
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>ภาพรีวิว</label>
                                {imageReviewURL?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxWidth: 300, border: "1px solid #EEEEEE" }}
                                        src={`${imageReviewURL.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: "100%", borderRadius: 8, border: "1px solid #C4C4C4" }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionReviewImage}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={imageReviewURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="isActive"
                                label="เเสดงรีวิวนี้หรือไม่ ?"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>เปิด</Option>
                                    <Option key={1} value={false}>ปิด</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
