import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

// Review
const insertReviewFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_REVIEWS_URL, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getReviewFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.get(server.GET_REVIEWS_URL + `?title=${param.title}&isActive=${param.isActive}&page=${param.page}&size=${param.size}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getReviewByIdFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.get(server.GET_REVIEW_BY_ID_URL + `/${param.id}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateReviewFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.put(server.UPDATE_REVIEW_URL + `/${param.id}`, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const deleteReviewByIdFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.delete(server.DELETE_REVIEW_URL + `/${param.id}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getReviewFetch,
    getReviewByIdFetch,

    // insert
    insertReviewFetch,

    // update
    updateReviewFetch,

    // delete
    deleteReviewByIdFetch
}
