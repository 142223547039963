import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const insertContactFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_CONTACT_URL, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    insertContactFetch,
    
    // insert

    // update

    // delete

}