import { baseApi2, server } from "../../../constants"
import { HttpClient2 } from "../../../utils/HttpClient2"

const getMobileBrandFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_MOBILE_BRAND_URL, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getMobileModelFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_MOBILE_MODEL_URL + `?mobileBrandId=${param.mobileBrandId}`, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getMobileCapacityFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_MOBILE_CAPACITY_URL + `?mobileModelId=${param.mobileModelId}`, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getBannerFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_BANNER_URL, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getReviewFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_REVIEW_URL + `?page=${param.page}&perPage=${param.perPage}`, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getSaleStepContentFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_SALE_STEP_CONTENT_URL, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getSaleByKingdomFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_SALE_BY_KINGDOM_URL, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getMobileBrandFetch,
    getMobileModelFetch,
    getMobileCapacityFetch,

    getBannerFetch,
    getReviewFetch,
    getSaleStepContentFetch,
    getSaleByKingdomFetch,

    // insert

    // update

    // delete
}