/* eslint-disable react-hooks/exhaustive-deps */

import Main from './components/main'
import {
    Row,
    Col
} from 'antd'
import {
    scroller,
    // Element,  
} from 'react-scroll'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import { useLocation } from 'react-router'
import Footer from '../../common/components/footer'
import ContactForm from './components/contactForm'

// import { useEffect, useState } from 'react'
// import { Icon } from '@iconify/react'
// import Gallery from '../../common/components/gallery'

export default function ContactUs() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const menu = params.get("menu")

    const { width } = useDimensions()

    // const genScroll = () => {
    //     if (menu === "product") {
    //         scroller.scrollTo("product", {
    //             duration: 1500,
    //             delay: 100,
    //             smooth: true,
    //             offset: -78,
    //         })
    //     } else if (menu === "about") {
    //         scroller.scrollTo("about", {
    //             duration: 1500,
    //             delay: 100,
    //             smooth: true,
    //             offset: -78,
    //         })
    //     } else if (menu === "contact") {
    //         scroller.scrollTo("contact", {
    //             duration: 1500,
    //             delay: 100,
    //             smooth: true,
    //             offset: -78,
    //         })
    //     }
    // }

    return (
        <>
            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        <Main />
                    </Col>

                    <Col span={24}>
                        <ContactForm />
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )
}
