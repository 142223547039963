import "./styles/App.css"
import "./styles/App.less"

import Home from "./modules/home"
import { ConfigProvider } from 'antd'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import themeAntd from './styles/themeAntd'
import LoginERP from "./modules/erp/login"
import ERP from "./modules/erp"
import MobilePurchase from "./modules/mobilePurchase"
import MobilePawn from "./modules/mobilePawn"
import ContactUs from "./modules/contactUs"
import FloatButtons from "./common/components/floatButton"
import EstimatePrice from "./modules/estimatePrice"
import SummaryResult from "./modules/summaryResult"
import Results from "./common/components/result"
import EvaluateIntegrity from "./modules/evaluateIntegrity"

const App = ({ authorized, firebase, user }) => (
    <ConfigProvider theme={{ token: themeAntd }}>
        <Router>
            <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route exact path="/mobile-purchase" element={<MobilePurchase/>} />
                <Route exact path="/mobile-pawn" element={<MobilePawn/>} />
                <Route exact path="/contact-us" element={<ContactUs/>} />
                <Route exact path="/estimate-price" element={<EstimatePrice/>} />
                <Route exact path="/evaluate-integrity" element={<EvaluateIntegrity/>} />
                <Route exact path="/summary-result" element={<SummaryResult/>} />
                <Route exact path="/result" element={<Results/>} />
                
                <Route exact path="/login-kingdom-store-back-office" element={<LoginERP/>} />
                <Route exact path="/kingdom-store-back-office" element={<ERP/>} />
                
                <Route path="/:id">Page not found!</Route>
            </Routes>
        </Router>
        <FloatButtons />
    </ConfigProvider>
)

export default App
