/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function HighlightService() {

    const { width } = useDimensions()

    return (
        <div>
            <div
                style={{
                    borderRadius: 8,
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Row
                    gutter={[24, 24]}
                    style={{
                        paddingTop: 84,
                        paddingBottom: 84,
                        paddingLeft: (width > 1200 ? 0 : 24),
                        paddingRight: (width > 1200 ? 0 : 24),
                        maxWidth: 1000,
                    }}
                >
                    <Col span={24} style={{ textAlign: "center" }}>
                        <label style={{ fontSize: 26 }}>ฝากกับเราดีกว่ายังไง ?</label>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ height: 90, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FF9900", borderRadius: "16px 0px 0px 16px" }}>
                                <img src={"./assets/images/icon/location.png"} style={{ height: 60, padding: 24 }} />
                            </div>

                            <div style={{ height: 90, width: "100%", display: "flex", alignItems: "flex-start", backgroundColor: "#EBEBEB", borderRadius: "0px 16px 16px 0px" }}>
                                <label style={{ fontSize: 16, padding: 12 }}>
                                    มีหน้าร้านชัดเจน ไม่ใช่ร้านตู้ ไม่ใช่รับฝากที่คอนโด มั่นใจ ปลอดภัย 100% พร้อมบริการหลังการขาย ดีเยี่ยม
                                </label>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ height: 90, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FF9900", borderRadius: "16px 0px 0px 16px" }}>
                                <img src={"./assets/images/icon/lock.png"} style={{ height: 60, padding: 24 }} />
                            </div>

                            <div style={{ height: 90, width: "100%", display: "flex", alignItems: "flex-start", backgroundColor: "#EBEBEB", borderRadius: "0px 16px 16px 0px" }}>
                                <label style={{ fontSize: 16, padding: 12 }}>
                                    มีห้องเก็บพร้อมตู้ล็อกเกอร์ ใส่ซองกันกระแทก รักษาเครื่องของลูกค้าให้อยู่ในสภาพเหมือนเดิม
                                </label>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ height: 90, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FF9900", borderRadius: "16px 0px 0px 16px" }}>
                                <img src={"./assets/images/icon/spark-plug.png"} style={{ height: 60, padding: 24 }} />
                            </div>

                            <div style={{ height: 90, width: "100%", display: "flex", alignItems: "flex-start", backgroundColor: "#EBEBEB", borderRadius: "0px 16px 16px 0px" }}>
                                <label style={{ fontSize: 16, padding: 12 }}>
                                    ไม่มีการแกะเครื่อง หรือดูข้อมูลไม่มีเปลี่ยนอะไหล่ อย่างแน่นอน 100% ปลอดภัยหายห่วง
                                </label>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ height: 90, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FF9900", borderRadius: "16px 0px 0px 16px" }}>
                                <img src={"./assets/images/icon/checklist.png"} style={{ height: 60, padding: 24 }} />
                            </div>

                            <div style={{ height: 90, width: "100%", display: "flex", alignItems: "flex-start", backgroundColor: "#EBEBEB", borderRadius: "0px 16px 16px 0px" }}>
                                <label style={{ fontSize: 16, padding: 12 }}>
                                    เรามีมาตรฐานในการตรวจรับสินค้า และการประเมินราคา มั่นใจได้ว่าลูกค้าจะได้ ราคาดีที่ดีสุด
                                </label>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
