/* eslint-disable react-hooks/exhaustive-deps */

import Main from './components/main'
import {
    Row,
    Col
} from 'antd'
import {
    scroller,
    // Element,  
} from 'react-scroll'
import { useDimensions } from '../../common/mamager/ScreenManager'
import Header from '../../common/components/header'
import { useLocation } from 'react-router'
import Footer from '../../common/components/footer'
import HighlightService from './components/highlightService'
import ContactSales from './components/contactSales'

// import { useEffect, useState } from 'react'
// import { Icon } from '@iconify/react'
// import Gallery from '../../common/components/gallery'

export default function MobilePawn() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const menu = params.get("menu")

    const { width } = useDimensions()

    // const genScroll = () => {
    //     if (menu === "product") {
    //         scroller.scrollTo("product", {
    //             duration: 1500,
    //             delay: 100,
    //             smooth: true,
    //             offset: -78,
    //         })
    //     } else if (menu === "about") {
    //         scroller.scrollTo("about", {
    //             duration: 1500,
    //             delay: 100,
    //             smooth: true,
    //             offset: -78,
    //         })
    //     } else if (menu === "contact") {
    //         scroller.scrollTo("contact", {
    //             duration: 1500,
    //             delay: 100,
    //             smooth: true,
    //             offset: -78,
    //         })
    //     }
    // }

    return (
        <>
            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        {/* <Element name="home"> */}
                        <Main />
                        {/* </Element> */}
                    </Col>

                    <Col span={24}>
                        {/* <Element name=""> */}
                        <HighlightService />
                        {/* </Element> */}
                    </Col>

                    <Col
                        span={24}
                    // style={{ borderTop: "1px solid #EEEEEE" }}
                    >
                        {/* <Element name="blog"> */}
                        <ContactSales />
                        {/* </Element> */}
                    </Col>

                    <Col
                        span={24}
                        style={{
                            display: "grid",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <div
                            style={{
                                paddingTop: 84,
                                paddingBottom: 84,
                                paddingLeft: (width > 1200 ? 0 : 24),
                                paddingRight: (width > 1200 ? 0 : 24),
                                maxWidth: 1000
                            }}
                        >
                            <label style={{ fontSize: 22, fontWeight: 600 }}>
                                รับฝาก รับจำนำโทรศัพท์มือถือ ทุกรุ่นทุกยี่ห้อ iPhone iPad Tablet Notebook Macbook อุปกรณ์ไอทีทุกชนิด ให้ราคาสูง
                            </label>
                            <br />

                            <label style={{ fontSize: 22 }}>
                                ใช้เวลาไม่นานรับเงินสดทันที เรามีหน้าร้านชัดเจนสะดวก ปลอดภัย รวดเร็ว บริการเงินด่วน หมุนเงินไม่ทันต้องการ ใช้เงินด่วนรับเงินสดทันที ทางเราไม่เน้นให้สินค้าหลุด ถึงวันครบกำหนดทางเราจะโทรแจ้งลูกค้า
                            </label>
                        </div>
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )
}
