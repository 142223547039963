/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Select,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Notification } from '../../../../common/components/notification'
import { getContactFetch, updateContactFetch } from './API/contactApi'
import { Switch } from "antd"

const { TextArea } = Input
const { Option } = Select

// Column Table
const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "5%"
    },
    {
        title: 'ชื่อ',
        dataIndex: 'name',
        width: "10%"
    },
    {
        title: 'เบอร์มือถือ',
        dataIndex: 'mobilePhone',
        width: "10%"
    },
    {
        title: 'อีเมล',
        dataIndex: 'email',
        width: "10%"
    },
    {
        title: 'รายละเอียด',
        dataIndex: 'description',
        width: "45%"
    },
    {
        title: 'สถานะติดต่อเรา',
        width: "10%",
        render: (text, record) => {
            return (
                <>
                    <label>{record.statusHandle}</label>
                </>
            )
        }
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%"
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "10%"
    }
]

const formatDate = "DD/MM/YYYY"

export default function ContactManage() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [formContact] = Form.useForm()
    const [formSearch] = Form.useForm()

    const accessToken = sessionStorage.getItem("accessToken")

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const searchNameRef = useRef("")

    const [modalContact, setModalContact] = useState({
        isShow: false,
        name: null
    })

    const handleSwitchChange = async (values) => {
        let param = {id: values.id}
        let body = { "statusHandle": values.statusHandle === "completed" ? "pending" : "completed" }

        const result = await updateContactFetch(param, body, accessToken)
        if (result && result.isSuccess) {
            Notification('success', 'เเก้ไขสำเร็จ')

            // reload
            getContactAll("")
        } else {
            Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const getContactAll = async (name) => {
        setLoading(true)

        let param = {
            name,
            isActive: "",
            page: pageCurrentRef.current,
            size: pageSize
        }
        const result = await getContactFetch(param, null, accessToken)
        setTotal(result?.totalItems)
        let tempList = []
        result?.results?.map((val, index) => {
            tempList.push({
                index: (pageCurrentRef.current * pageSize - 10) + (index + 1),
                name: val.name,
                mobilePhone: val.mobilePhone,
                email: val.email,
                description: val.description,
                isActive: val.isActive,
                statusHandle: val.statusHandle,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Switch
                            checked={val.statusHandle === "completed" ? true : false}
                            checkedChildren="completed"
                            unCheckedChildren="pending"
                            onChange={async () => handleSwitchChange({
                                id: val.id,
                                statusHandle: val.statusHandle
                            })}
                        />
                    </>
            })
        })
        setList(tempList)
        searchNameRef.current = name

        setLoading(false)
    }

    const onContactFinish = async (values) => {
        let param = {id: values.id ? values.id : ""}
        let body = {"statusHandle": values.statusHandle ? values.statusHandle : ""}

        if (modalContact.title === "edit") {
            const result = await updateContactFetch(param, body, accessToken)
            if (result && result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getContactAll("")

        // set default
        setFormContactDefault()
    }

    const onSearchFinish = async (values) => {
        let name = values?.name ? values.name : ""
        await getContactAll(name)
    }

    const onPagine = (n) => {
        pageCurrentRef.current = n.current
        getContactAll(searchNameRef.current)
    }

    const setFormContactDefault = () => {
        formContact.setFieldsValue({
            name: undefined,
            mobilePhone: undefined,
            email: undefined,
            description: undefined,
            isActive: undefined,
            statusHandle: undefined
        })

        setModalContact({
            isShow: false,
            title: null
        })
    }

    const getBaseApi = async () => {
        getContactAll("")
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการติดต่อเรา</label>
            </Col>

            <Col span={12} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                name="name"
                                style={{ width: '100%' }}
                            >
                                <Input placeholder="ค้นหาชื่อติดต่อเรา" />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: -24 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrentRef.current,
                        pageSize: pageSize,
                        total: total
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modalContact.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modalContact.isShow}
                zIndex={999}
                onCancel={() => {
                    // default
                    setFormContactDefault()
                }}
                width={700}
                onOk={() => {
                    formContact.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={formContact}
                    onFinish={onContactFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="id" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="name"
                                label="ชื่อ"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อติดต่อเรา' }]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="mobilePhone"
                                label="เบอร์มือถือ"
                                rules={[{ required: true, message: 'กรุณากรอก เบอร์มือถือ' }]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="email"
                                label="อีเมล"
                                rules={[{ required: true, message: 'กรุณากรอก อีเมล' }]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="description"
                                label="รายละเอียด"
                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียด' }]}
                            >
                                <TextArea autoSize={{ minRows: 1, maxRows: 2 }} disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="statusHandle"
                                label="สถานะติดต่อเรา ?"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะติดต่อเรา' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value='pending'>pending</Option>
                                    <Option key={1} value='completed'>completed</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="isActive"
                                label="เเสดงติดต่อเรานี้หรือไม่ ?"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    disabled={true}
                                >
                                    <Option key={0} value={true}>เปิด</Option>
                                    <Option key={1} value={false}>ปิด</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}