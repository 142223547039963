/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Select,
  Input,
  InputNumber,
  Checkbox,
} from "antd";
import { Icon } from "@iconify/react";

import { useNavigate } from "react-router";
// import { useLocation } from "react-router-dom"
import { useDimensions } from "../../common/mamager/ScreenManager";

import Footer from "../../common/components/footer";
import Header from "../../common/components/header";

import dayjs from "dayjs";
import "dayjs/locale/th"; // นำเข้า locale ภาษาไทย
import buddhistEra from "dayjs/plugin/buddhistEra"; // นำเข้า plugin สำหรับพุทธศักราช

import "./css/index.css";
import { Notification } from "../../common/components/notification";
import {
  getDistrictFetch,
  getProvinceFetch,
  getTrainFetch,
  getTrainLineFetch,
  getTrainStationFetch,
  insertSaleInfoFetch,
} from "./API/summaryResultApi";
import moment from "moment";
import StepSaleMobile from "../../common/components/step";
import { Modal } from "antd/lib";

const { Option } = Select;

dayjs.extend(buddhistEra);
dayjs.locale("th"); // ตั้งค่า locale ภาษาไทย

export default function SummaryResult(props) {
  const [form] = Form.useForm();
  const { width } = useDimensions();

  const navigate = useNavigate();

  const userAgent = navigator.userAgent
  const isMobileDevice = /iPhone|Android|iPad|Tablet/.test(userAgent)
  // console.log("isMobileDevice : ", isMobileDevice)

  const [saleInfo, setSaleInfo] = useState(
    JSON.parse(localStorage.getItem("saleInfo"))
  );
  const [deviceInfo, setDeviceInfo] = useState(
    JSON.parse(localStorage.getItem("deviceInfo"))
  );
  const [issueInfo, setIssueInfo] = useState(
    JSON.parse(localStorage.getItem("issueInfo"))
  );

  const [isSelectPlace, setIsSelectPlace] = useState("buy-at-home"); // buy-along-train

  const [policy, setPolicy] = useState(true);

  const [modalPolicy, setModalPolicy] = useState({
    isShow: false,
  });

  const onFinish = async (values) => {
    let issueOptions = [];
    for (let i = 0; i < issueInfo.length; i++) {
      issueOptions.push({
        issueOptionsId: issueInfo[i].id,
      });
    }

    let purchase = null;
    if (isSelectPlace === "buy-at-home") {
      purchase = 1;
    } else if (isSelectPlace === "buy-along-train") {
      purchase = 2;
    } else if (isSelectPlace === "buy-at-store") {
      purchase = 3;
    }

    let obj = {}
    if (!isMobileDevice) {
      obj = {
        mobileBrandId: deviceInfo?.mobileBrandId,
        mobileModelId: deviceInfo?.mobileModelId,
        mobileCapacityId: deviceInfo?.mobileCapacityId,
        customerInfo: {
          firtsName: values.name.split(" ")[0] ?? "-",
          lastName: values.name.split(" ")[1] ?? "-",
          telephone: values?.phoneNumber ? values.phoneNumber : null,
        },
        placePurchase: {
          purchaseId: purchase,
          districtId: values?.district ? values.district : null,
          trainStationId: values?.trainStation ? values.trainStation : null,
        },
        appointmentDate: moment(
          `${values.day} ${values.time}`,
          "DD/MM/YYYY HH:mm"
        )
          .add(7, "hours")
          .toISOString(),
        landMark: values?.landMark ? values.landMark : "-",
        address: values?.address ? values.address : "-",
        totalSummary: saleInfo.summarySaleInfo,
        isActive: true,
        issueOptions: issueOptions,
      }
    } else {
      obj = {
        mobileBrandId: deviceInfo?.mobileBrandId,
        mobileModelId: deviceInfo?.mobileModelId,
        mobileCapacityId: deviceInfo?.mobileCapacityId,
        customerInfo: {
          firtsName: values.name.split(" ")[0] ?? "-",
          lastName: values.name.split(" ")[1] ?? "-",
          telephone: values?.phoneNumber ? values.phoneNumber : null,
        },
        placePurchase: {
          purchaseId: purchase,
          districtId: selectedDistrict ? selectedDistrict.id : null,
          trainStationId: selectedTrainStation ? selectedTrainStation.id : null,
        },
        appointmentDate: moment(
          `${selectedDay} ${selectedTime}`,
          "DD/MM/YYYY HH:mm"
        )
          .add(7, "hours")
          .toISOString(),
        landMark: values?.landMark ? values.landMark : "-",
        address: values?.address ? values.address : "-",
        totalSummary: saleInfo.summarySaleInfo,
        isActive: true,
        issueOptions: issueOptions,
      }
    }
    // console.log("obj --- : ", obj)

    if (values.phoneNumber === values.confirmPhoneNumber) {
      if (policy) {
        const result = await insertSaleInfoFetch(null, obj, null);
        if ([200, 201].includes(result?.status)) {
          navigate("/result", {
            state: {
              type: "success",
              title: "ส่งข้อมูลเรียบร้อย",
              subTitle: "โปรดรอทางเจ้าหน้าทีติดต่อกลับ เเละเข้าไปรับสินค้า",
              btnIsActive1: true,
              btnWidth: 250,
              btnPathName1: "/estimate-price",
              btnName1: "กลับไปหน้าหลัก",
            },
          });
          Notification("success", "สร้างสำเร็จ");
        } else {
          Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
        }
      } else {
        Notification(
          "error",
          "เเจ้งเตือน",
          "กรุณาคลิกยอมรับข้อตกลงและเงื่อนไข !"
        );
      }
    } else {
      Notification(
        "error",
        "เเจ้งเตือน",
        "ไม่สามารถส่งข้อมูลได้ กรุณาตรวจเบอร์โทร"
      );
    }
  };

  const today = dayjs();

  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [day, setDay] = useState();
  const [time, setTime] = useState();
  const [trainLine, setTrainLine] = useState();
  const [trainStation, setTrainStation] = useState();

  const [isModalOpenProvince, setModalOpenProvince] = useState(false)
  const [isModalOpenDistrict, setModalOpenDistrict] = useState(false)
  const [isModalOpenDay, setModalOpenDay] = useState(false)
  const [isModalOpenTime, setModalOpenTime] = useState(false)
  const [isModalOpenTrainLine, setModalOpenTrainLine] = useState(false)
  const [isModalOpenTrainStation, setModalOpenTrainStation] = useState(false)

  const [selectedProvince, setSelectedProvince] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [selectedTrainLine, setSelectedTrainLine] = useState(null)
  const [selectedTrainStation, setSelectedTrainStation] = useState(null)

  const openModalProvince = (e) => {
    e.preventDefault()
    setModalOpenProvince(true)
  }

  const openModalDistrict = (e) => {
    e.preventDefault()
    setModalOpenDistrict(true)
  }

  const openModalDay = (e) => {
    e.preventDefault()
    setModalOpenDay(true)
  }

  const openModalTime = (e) => {
    e.preventDefault()
    setModalOpenTime(true)
  }

  const openModalTrainLine = (e) => {
    e.preventDefault()
    setModalOpenTrainLine(true)
  }

  const openModalTrainStation = (e) => {
    e.preventDefault()
    setModalOpenTrainStation(true)
  }

  const closeModalProvince = () => {
    setModalOpenProvince(false)
  }

  const closeModalDistrict = () => {
    setModalOpenDistrict(false)
  }

  const closeModalDay = () => {
    setModalOpenDay(false)
  }

  const closeModalTime = () => {
    setModalOpenTime(false)
  }

  const closeModalTrainLine = () => {
    setModalOpenTrainLine(false)
  }

  const closeModalTrainStation = () => {
    setModalOpenTrainStation(false)
  }

  const genDay = () => {
    const options = [];
    for (let i = 0; i <= 7; i++) {
      const date = today.add(i, "day");
      const formattedDate = date.format("D MMMM BBBB"); // รูปแบบวันที่ "1 สิงหาคม 2567"
      const formatDate = date.format("DD/MM/YYYY");
      // console.log("x --- : ", formatDate);

      options.push(
        <Option key={i} value={formatDate}>
          {formattedDate}
        </Option>
      );
    }
    // console.log("day --- : ", options)
    setDay(options);
  };

  const genTime = () => {
    const options = [];
    for (let hour = 11; hour <= 20; hour++) {
      // แปลงรูปแบบชั่วโมงเป็นสองหลัก เช่น 00, 01, 02,..., 23
      const hourStr = hour.toString().padStart(2, "0");

      // สร้างตัวเลือกสำหรับนาที 00
      options.push(
        <Option key={`${hourStr}:00`} value={`${hourStr}:00`}>
          {`${hourStr}:00`}
        </Option>
      );

      // สร้างตัวเลือกสำหรับนาที 30
      options.push(
        <Option key={`${hourStr}:30`} value={`${hourStr}:30`}>
          {`${hourStr}:30`}
        </Option>
      );
    }
    // console.log("time --- : ", options)
    setTime(options);
  };

  const getProvince = async () => {
    const result = await getProvinceFetch();
    // console.log("getProvinceFetch : ", result?.result)
    if (result?.status === 200) {
      setProvince(result?.result);
    }
  };

  const getDistrict = async (id) => {
    let param = {
      id,
    };
    const result = await getDistrictFetch(param, null, null);
    // console.log("getDistrictFetch : ", result?.result)
    if (result?.status === 200) {
      setDistrict(result?.result);
    }
  };

  const getTrainLine = async () => {
    let param = {};
    const result = await getTrainLineFetch(param, null, null);
    // console.log("getTrainLineFetch : ", result?.result)
    if (result?.status === 200) {
      setTrainLine(result?.result);
    }
  };

  const getTrainStation = async (id) => {
    let param = {
      trainCategoriesId: id,
    };
    const result = await getTrainStationFetch(param, null, null);
    // console.log("getTrainStationFetch : ", result?.result)
    if (result?.status === 200) {
      setTrainStation(result?.result);
    }
  };

  const getBaseApi = async () => {
    genDay();
    genTime();
    getProvince();
    getDistrict();
    getTrainLine();

    form.setFieldValue("policy", true);
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <>
      <Header />

      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            paddingTop:
              width > 1200 ? 150 : width > 760 && width < 1200 ? 150 : 94,
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <StepSaleMobile stepId={2} />
        </div>
        <div
          style={{
            display: width > 1050 ? "flex" : "grid",
            alignItems: "flex-start",
            paddingTop: width > 1200 ? 48 : 24,
            paddingBottom: 82,
            paddingLeft: width > 1200 ? 0 : 12,
            paddingRight: width > 1200 ? 0 : 12,
            maxWidth: 1000,
          }}
        >
          <div
            style={{
              width: width > 1050 ? 400 : "100%",
              paddingRight: width > 1050 ? 24 : 0,
              paddingBottom: width > 1050 ? 0 : 24,
            }}
          >
            <div style={{ paddingBottom: 24 }}>
              {!isMobileDevice ?
                <div className="card-purchase-item">
                  <div style={{ display: "grid" }}>
                    <div style={{ paddingBottom: 12 }}>
                      <label className="ks-item-title">รายการรับซื้อ</label>
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={deviceInfo?.mobileModelImgUrl ?? ""}
                        style={{ width: "100%", objectFit: "cover" }}
                      />
                    </div>

                    <div style={{ textAlign: "center", paddingTop: 12 }}>
                      <label style={{ fontSize: 20 }}>
                        {deviceInfo?.mobileModelTitle ?? "-"} {deviceInfo?.mobileCapacityName ?? "-"}
                      </label>
                    </div>
                  </div>
                </div>
                :
                <div style={{ display: "flex", justifyContent: "flex-start", textAlign: "center" }}>
                  {/* {console.log("deviceInfo : ", deviceInfo)} */}
                  <div style={{ width: 3, height: 28, backgroundColor: "rgb(255, 187, 85)", padding: 2 }}></div>
                  <label style={{ fontSize: 20, paddingLeft: 8 }} >ระบุการขาย {deviceInfo?.mobileModelTitle ?? "-"} {deviceInfo?.mobileCapacityName ?? "-"}</label>
                </div>
              }
            </div>

            <div style={{ paddingBottom: 24 }}>
              <div className="card-evaluation-result">
                <div style={{ display: "grid" }}>
                  <div style={{ padding: "24px 24px 0px 24px" }}>
                    <label className="ks-item-detail">ผลการประเมิน</label>
                  </div>

                  <ul style={{ display: "grid", padding: "0px 24px 0px 40px" }}>
                    {issueInfo?.map((item, index) => {
                      return (
                        <li key={index}>
                          <label style={{ fontWeight: 400 }}>
                            {item?.title ?? "-"}
                          </label>
                        </li>
                      );
                    })}
                  </ul>

                  <div
                    style={{
                      textAlign: "center",
                      padding: 12,
                      backgroundColor: "#FFBB55",
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8,
                    }}
                  >
                    <label className="ks-card-title">
                      ยอดรวมสุทธิ{" "}
                      {Intl.NumberFormat("en", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(
                        Number(
                          saleInfo?.summarySaleInfo >= 0
                            ? saleInfo?.summarySaleInfo
                            : saleInfo?.minPrice
                        )
                      )}{" "}
                      บาท
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="purchasing-location">
            <div style={{ width: width > 1050 ? 600 : "100%" }}>
              <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={[width > 430 ? 24 : 0, 0]}>
                  <Col span={24}>
                    <div style={{ paddingBottom: 12 }}>
                      <label className="ks-item-title">สถานที่รับซื้อ</label>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    style={{ paddingBottom: width > 992 ? 0 : 12 }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        width: "100%",
                        backgroundColor:
                          isSelectPlace === "buy-at-home" ? "" : "#D6D6D6",
                      }}
                      onClick={() => {
                        setIsSelectPlace("buy-at-home");
                      }}
                    >
                      <label style={{ cursor: "pointer", color: "black" }}>
                        รับซื้อถึงบ้าน
                      </label>
                    </Button>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    style={{ paddingBottom: width > 992 ? 0 : 12 }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      style={{
                        width: "100%",
                        backgroundColor:
                          isSelectPlace === "buy-along-train" ? "" : "#D6D6D6",
                      }}
                      onClick={() => {
                        setIsSelectPlace("buy-along-train");
                      }}
                    >
                      <label style={{ cursor: "pointer", color: "black" }}>
                        รับซื้อตามรถไฟฟ้า
                      </label>
                    </Button>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={8}>
                    <Button
                      type="primary"
                      style={{
                        width: "100%",
                        backgroundColor:
                          isSelectPlace === "buy-at-store" ? "" : "#D6D6D6",
                      }}
                      onClick={() => {
                        setIsSelectPlace("buy-at-store");
                      }}
                      size="large"
                    >
                      <label style={{ cursor: "pointer", color: "black" }}>
                        ไปขายที่หน้าร้าน
                      </label>
                    </Button>
                    <label style={{ color: "red", fontSize: 13 }}>
                      * มาขายที่หน้าร้านรับเพิ่ม 500 บาท
                    </label>
                  </Col>

                  <Col span={24}>
                    <div style={{ paddingTop: 24 }}>
                      <Form.Item
                        name="name"
                        label="ชื่อ-นามสกุล"
                        rules={[
                          { required: true, message: "กรุณากรอกชื่อ-นามสกุล" },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="กรอกข้อมูล"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {isSelectPlace === "buy-at-home" ? (
                    <>
                      <Col span={24}>
                        <Form.Item
                          name="address"
                          label={
                            <div>
                              ที่อยู่
                              <span style={{ paddingLeft: 12, color: "red" }}>
                                หากต้องการขายด่วนภายในวันนี้ กรุณาติดต่อ090-924-3388
                              </span>
                            </div>
                          }
                          rules={[
                            { required: true, message: "กรุณากรอกที่อยู่" },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="กรอกข้อมูล"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="landMark"
                          label={<div>สถานที่จุดสังเกตุเพิ่มเติม</div>}
                        >
                          <Input
                            size="large"
                            placeholder="กรอกข้อมูล"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                          name="province"
                          label="จังหวัด"
                          rules={[
                            {
                              required: (!isMobileDevice ? true : (selectedProvince ? false : true)),
                              message: "กรุณาเลือกจังหวัด"
                            },
                          ]}
                        >
                          {!isMobileDevice ?
                            <Select
                              showSearch
                              placeholder="เลือกรายการ"
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              allowClear
                              size="large"
                              onChange={async (e) => {
                                await getDistrict(e);
                              }}
                            >
                              {province?.map((item, index) => {
                                return (
                                  <Option key={index} value={item.id}>
                                    {item.title}
                                  </Option>
                                );
                              })}
                            </Select>
                            :
                            <div>
                              <div className="select-mobile" onClick={openModalProvince}>
                                <div>
                                  {selectedProvince ?
                                    <span style={{ fontSize: 18, color: "black" }}>{selectedProvince.title}</span>
                                    :
                                    <span style={{ fontSize: 18, color: "#C3C3C3" }}>เลือกจังหวัด</span>
                                  }
                                </div>
                                <Icon icon="uiw:down" style={{ width: 16, height: 20, color: "#C3C3C3" }} />
                              </div>

                              {isModalOpenProvince && (
                                <div className="modal">
                                  <div className="modal-content">
                                    <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}>
                                      <span className="close" onClick={closeModalProvince}>&times;</span>
                                      <label>จังหวัด</label>
                                    </div>
                                    <div className="options">
                                      {province?.map((item, index) => (
                                        <div
                                          className="option"
                                          key={index}
                                          onClick={async () => {
                                            await getDistrict(item.id)
                                            setSelectedProvince(item)
                                            closeModalProvince()
                                          }}
                                        >
                                          {item?.title}
                                        </div>
                                      ))}
                                      {province?.length === 0 ? <div className="option">ไม่พบรายการ</div> : []}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                          name="district"
                          label="เขต/อำเภอ"
                          rules={[
                            {
                              required: (!isMobileDevice ? true : (selectedDistrict ? false : true)),
                              message: "กรุณาเลือกเขต/อำเภอ"
                            },
                          ]}
                        >
                          {!isMobileDevice ?
                            <Select
                              showSearch
                              placeholder="เลือกรายการ"
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              allowClear
                              size="large"
                            >
                              {district?.map((item, index) => {
                                return (
                                  <Option key={index} value={item.id}>
                                    {item.title}
                                  </Option>
                                );
                              })}
                            </Select>
                            :
                            <div>
                              <div className="select-mobile" onClick={openModalDistrict}>
                                <div>
                                  {selectedDistrict ?
                                    <span style={{ fontSize: 18, color: "black" }}>{selectedDistrict.title}</span>
                                    :
                                    <span style={{ fontSize: 18, color: "#C3C3C3" }}>เลือกเขต/อำเภอ</span>
                                  }
                                </div>
                                <Icon icon="uiw:down" style={{ width: 16, height: 20, color: "#C3C3C3" }} />
                              </div>

                              {isModalOpenDistrict && (
                                <div className="modal">
                                  <div className="modal-content">
                                    <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}>
                                      <span className="close" onClick={closeModalDistrict}>&times;</span>
                                      <label>เขต/อำเภอ</label>
                                    </div>
                                    <div className="options">
                                      {district?.map((item, index) => (
                                        <div
                                          className="option"
                                          key={index}
                                          onClick={async () => {
                                            setSelectedDistrict(item)
                                            closeModalDistrict()
                                          }}
                                        >
                                          {item?.title}
                                        </div>
                                      ))}
                                      {district?.length === 0 ? <div className="option">ไม่พบรายการ</div> : []}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    []
                  )}

                  {isSelectPlace === "buy-along-train" ? (
                    <>
                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                          name="trainLine"
                          label="สายรถไฟฟ้า"
                          rules={[
                            {
                              required: (!isMobileDevice ? true : (selectedTrainLine ? false : true)),
                              message: "กรุณาเลือกสายรถไฟฟ้า"
                            },
                          ]}
                        >
                          {!isMobileDevice ?
                            <Select
                              showSearch
                              placeholder="เลือกรายการ"
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              allowClear
                              size="large"
                              onChange={async (e) => {
                                await getTrainStation(e)
                              }}
                            >
                              {trainLine?.map((item, index) => {
                                return (
                                  <Option key={index} value={item.id}>
                                    {item.title}
                                  </Option>
                                );
                              })}
                            </Select>
                            :
                            <div>
                              <div className="select-mobile" onClick={openModalTrainLine}>
                                <div>
                                  {selectedTrainLine ?
                                    <span style={{ fontSize: 18, color: "black" }}>{selectedTrainLine.title}</span>
                                    :
                                    <span style={{ fontSize: 18, color: "#C3C3C3" }}>เลือกสายรถไฟฟ้า</span>
                                  }
                                </div>
                                <Icon icon="uiw:down" style={{ width: 16, height: 20, color: "#C3C3C3" }} />
                              </div>

                              {isModalOpenTrainLine && (
                                <div className="modal">
                                  <div className="modal-content">
                                    <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}>
                                      <span className="close" onClick={closeModalTrainLine}>&times;</span>
                                      <label>สายรถไฟฟ้า</label>
                                    </div>
                                    <div className="options">
                                      {trainLine?.map((item, index) => (
                                        <div
                                          className="option"
                                          key={index}
                                          onClick={async () => {
                                            await getTrainStation(item.id)
                                            setSelectedTrainLine(item)
                                            closeModalTrainLine()
                                          }}
                                        >
                                          {item?.title}
                                        </div>
                                      ))}
                                      {trainLine?.length === 0 ? <div className="option">ไม่พบรายการ</div> : []}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                          name="trainStation"
                          label="สถานีรถไฟฟ้า"
                          rules={[
                            {
                              required: (!isMobileDevice ? true : (selectedTrainStation ? false : true)),
                              message: "กรุณาเลือกสถานีรถไฟฟ้า"
                            },
                          ]}
                        >
                          {!isMobileDevice ?
                            <Select
                              showSearch
                              placeholder="เลือกรายการ"
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              allowClear
                              size="large"
                            >
                              {trainStation?.map((item, index) => {
                                return (
                                  <Option key={index} value={item.id}>
                                    {item.title}
                                  </Option>
                                );
                              })}
                            </Select>
                            :
                            <div>
                              <div className="select-mobile" onClick={openModalTrainStation}>
                                <div>
                                  {selectedTrainStation ?
                                    <span style={{ fontSize: 18, color: "black" }}>{selectedTrainStation.title}</span>
                                    :
                                    <span style={{ fontSize: 18, color: "#C3C3C3" }}>เลือกสถานีรถไฟฟ้า</span>
                                  }
                                </div>
                                <Icon icon="uiw:down" style={{ width: 16, height: 20, color: "#C3C3C3" }} />
                              </div>

                              {isModalOpenTrainStation && (
                                <div className="modal">

                                  <div className="modal-content">
                                    <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}>
                                      <span className="close" onClick={closeModalTrainStation}>&times;</span>
                                      <label>สถานีรถไฟฟ้า</label>
                                    </div>
                                    <div className="options">
                                      {trainStation?.map((item, index) => (
                                        <div
                                          className="option"
                                          key={index}
                                          onClick={async () => {
                                            setSelectedTrainStation(item)
                                            closeModalTrainStation()
                                          }}
                                        >
                                          {item?.title}
                                        </div>
                                      ))}
                                      {trainStation?.length === 0 ? <div className="option">ไม่พบรายการ</div> : []}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          }
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    []
                  )}

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="day"
                      label="วัน"
                      rules={[{
                        required: (!isMobileDevice ? true : (selectedDay ? false : true)),
                        message: "กรุณาเลือกวัน"
                      }]}
                    >
                      {!isMobileDevice ?
                        <Select
                          showSearch
                          placeholder="เลือกรายการ"
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          allowClear
                          size="large"
                        >
                          {day?.map((item) => {
                            return <>{item}</>;
                          })}
                        </Select>
                        :
                        <div>
                          <div className="select-mobile" onClick={openModalDay}>
                            <div>
                              {selectedDay ?
                                <span style={{ fontSize: 18, color: "black" }}>{selectedDay}</span>
                                :
                                <span style={{ fontSize: 18, color: "#C3C3C3" }}>เลือกวัน</span>
                              }
                            </div>
                            <Icon icon="uiw:down" style={{ width: 16, height: 20, color: "#C3C3C3" }} />
                          </div>

                          {isModalOpenDay && (
                            <div className="modal">
                              <div className="modal-content">
                                <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}>
                                  <span className="close" onClick={closeModalDay}>&times;</span>
                                  <label>วัน</label>
                                </div>
                                <div className="options">
                                  {day?.map((item, index) => (
                                    <div
                                      className="option"
                                      key={index}
                                      onClick={async () => {
                                        setSelectedDay(item?.props?.value)
                                        closeModalDay()
                                      }}
                                    >
                                      {item?.props?.children}
                                    </div>
                                  ))}
                                  {day?.length === 0 ? <div className="option">ไม่พบรายการ</div> : []}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="time"
                      label={
                        <div>
                          เวลา
                          <span style={{ paddingLeft: 12, color: "red" }}>เวลาที่รับ 11:00 - 20:00</span>
                        </div>
                      }
                      rules={[{
                        required: (!isMobileDevice ? true : (selectedTime ? false : true)),
                        message: "กรุณาเลือกเวลา"
                      }]}
                    >
                      {!isMobileDevice ?
                        <Select
                          showSearch
                          placeholder="เลือกรายการ"
                          style={{ width: "100%" }}
                          optionFilterProp="children"
                          allowClear
                          size="large"
                        >
                          {time?.map((item) => {
                            return <>{item}</>;
                          })}
                        </Select>
                        :
                        <div>
                          <div className="select-mobile" onClick={openModalTime}>
                            <div>
                              {selectedTime ?
                                <span style={{ fontSize: 18, color: "black" }}>{selectedTime}</span>
                                :
                                <span style={{ fontSize: 18, color: "#C3C3C3" }}>เลือกเวลา</span>
                              }
                            </div>
                            <Icon icon="uiw:down" style={{ width: 16, height: 20, color: "#C3C3C3" }} />
                          </div>

                          {isModalOpenTime && (
                            <div className="modal">

                              <div className="modal-content">
                                <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 20 }}>
                                  <span className="close" onClick={closeModalTime}>&times;</span>
                                  <label>เวลา</label>
                                </div>
                                <div className="options">
                                  {time?.map((item, index) => (
                                    <div
                                      className="option"
                                      key={index}
                                      onClick={async () => {
                                        setSelectedTime(item?.props?.value)
                                        closeModalTime()
                                      }}
                                    >
                                      {/* {console.log(item?.props?.children)} */}
                                      {item?.props?.children}
                                    </div>
                                  ))}
                                  {time?.length === 0 ? <div className="option">ไม่พบรายการ</div> : []}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="phoneNumber"
                      label="หมายเลขโทรศัพท์"
                      rules={[
                        { required: true, message: "กรุณากรอกหมายเลขโทรศัพท์" },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="กรอกข้อมูล"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      name="confirmPhoneNumber"
                      label="ยืนยันเบอร์โทร"
                      rules={[
                        { required: true, message: "กรุณากรอกยืนยันเบอร์โทร" },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="กรอกข้อมูล"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <div style={{ paddingBottom: 24 }}>
                      <Checkbox
                        checked={policy}
                        onChange={() => {
                          setPolicy(!policy);
                        }}
                      >
                        ฉันยอมรับข้อตกลงและเงื่อนไข{" "}
                        <span
                          onClick={() => {
                            setModalPolicy({ isShow: true });
                          }}
                          style={{ color: "#FF9900" }}
                        >
                          การรับซื้อสินค้าของทาง Kingdom Store
                        </span>
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>

            <div>
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                onClick={() => {
                  window.scroll(0, 0);
                  form.submit();
                }}
              >
                <label style={{ cursor: "pointer", color: "black" }}>
                  ส่งให้ร้าน
                </label>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={"ข้อตกลงและเงื่อนไข"}
        visible={modalPolicy.isShow}
        zIndex={999}
        onCancel={() => {
          setModalPolicy({
            isShow: false,
          });
        }}
        width={700}
        onOk={() => { }}
        footer={[]}
      >
        ...
      </Modal>

      <Footer />

      <style jsx>{`
        .modal {
          position: fixed;
          z-index: 1000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
          background-color: rgba(0, 0, 0, 0.0);
        }
        .modal-content {
          position: absolute;
          top: 30%; /* ปรับให้สูงขึ้น */
          left: 50%;
          transform: translate(-50%, -30%); /* ปรับตามตำแหน่งที่สูงขึ้น */
          background-color: rgba(255, 153, 0);
          box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
          color: white;
          // padding: 20px;
          // border: 1px solid #888;
          border-radius: 8px;
          width: 300px;
          max-width: 500px;
          max-height: 90%; /* กำหนดความสูงสูงสุด */
          overflow-y: auto; /* ให้มี scroll เมื่อข้อมูลเกิน */
        }
        .close {
          color: white;
          float: right;
          font-size: 28px;
          font-weight: bold;
          margin-top: -11px;
        }
        .close:hover,
        .close:focus {
          color: white;
          text-decoration: none;
          cursor: pointer;
        }
        .options {
          margin-top: 20px;
        }
        .option {
          padding: 10px;
          cursor: pointer;
          transition: background-color 0.3s;
          border: 1px solid white; 
          border-width: 1px 0px 0px 0px;
        }
        .option:hover {
          background-color: rgba(255, 255, 255, 0.2); /* สีพื้นหลังเมื่อ hover */
        }
        .select-mobile {
          border: 1.5px solid #BBC4C2;
          padding-left: 12px;
          padding-right: 12px;
          padding-top: 6px;
          padding-bottom: 6px;
          border-radius: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
        }
      `}</style>

    </>
  );
}
