import { server } from "../../../constants"
import { HttpClient2 } from "../../../utils/HttpClient2"

const getIssueFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_ISSUE_URL + `?mobileModelId=${param.mobileModelId}&mobileBrandId=${param.mobileBrandId}`, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const insertSaleInfoCalculateFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.post(server.GET_SALE_INFO_CALCULATE_URL, 
            body, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getIssueFetch,

    // insert
    insertSaleInfoCalculateFetch,
    
    // update

    // delete
}