/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useState } from "react"
import "../footer/css/index.css"
import { BackTop, Col, Image, Row, Tooltip, Input, Button, Modal } from "antd"
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../mamager/ScreenManager"

function Footer() {

    const navigate = useNavigate()
    const { width } = useDimensions()

    const [isModalLineVisible, setIsModalLineVisible] = useState(false)

    const handleModalLine = () => {
        setIsModalLineVisible(false)
    }

    return (
        <footer>
            <div
                style={{
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#262626",
                }}
            >
                <nav
                    style={{

                        color: "white",
                        // paddingLeft: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
                        // paddingRight: width > 1200 ? "10%" : (width > 760 && width < 1200 ? 12 : 12),
                        paddingTop: 50,
                        paddingBottom: 50,
                        maxWidth: 1000,
                    }}
                >
                    <Row gutter={[0, 24]}>
                        <Col xs={24} md={12} xl={8}>
                            <div style={{ display: "grid", paddingLeft: (width > 1200 ? 0 : 24) }}>
                                <div style={{ paddingBottom: (width > 1200 ? 0 : 24) }}>
                                    <Image
                                        fallback="./assets/images/logo/logo-kingdom-store.jpg"
                                        src="error"
                                        preview={false}
                                        style={{ width: 100, height: 100, borderRadius: 16 }}
                                    />
                                </div>

                                <div>
                                    <div style={{ paddingBottom: 12, paddingTop: 12 }}>
                                        <label style={{ fontSize: 22, fontWeight: 600 }}>Kingdom Store</label>
                                    </div>

                                    <div style={{ paddingBottom: 12 }}>
                                        <label style={{}}>รับซื้อเเละรับจำนำ มือถือทุกรุ่น iPhone iPad Tablet MacBook Notebook ให้ราคาสูง</label>
                                    </div>

                                    <div style={{ paddingBottom: 12 }}>
                                        <label style={{}}>ที่อยู่ ชั้น2 ห้อง2056 ห้างฟอร์จูนทาวน์ 1 ถ. รัชดาภิเษก แขวงดินแดง เขตดินแดง กรุงเทพมหานคร 10400</label>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={8}>
                            <div style={{ display: "grid", alignItems: "flex-start", paddingLeft: (width > 767 ? 0 : 24) }}>
                                <div
                                    style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                    onClick={() => {
                                        window.open("tel:+66909243388")
                                    }}
                                >
                                    <div style={{ height: 70, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FF9900", borderRadius: "16px 0px 0px 16px" }}>
                                        <img
                                            src={"./assets/images/icon/phone-call.png"}
                                            style={{ height: 40, padding: 24 }}
                                            alt="Logo Kingdom Store"
                                        />
                                    </div>

                                    <div style={{ height: 70, minWidth: 180, backgroundColor: "#D9D9D9", borderRadius: "0px 16px 16px 0px" }}>
                                        <div style={{ padding: 12 }}>
                                            <label style={{ fontSize: 16, color: "black", cursor: "pointer" }}>โทร</label>
                                            <br />
                                            <label style={{ fontSize: 16, fontWeight: 600, color: "black", cursor: "pointer" }}>090-924-3388</label>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: "flex", alignItems: "center", paddingTop: 12 }}>
                                    {/* <div style={{ paddingLeft: 0 }}>
                                        <Button
                                            type="primary"
                                            style={{ width: 38 }}
                                            size="middle"
                                            onClick={(e) => {
                                                window.open("https://www.messenger.com/t/kingdomstore888")
                                            }}
                                        >
                                            <div style={{ marginTop: 3, marginLeft: -7 }} >
                                                <Icon icon="cib:facebook-f" style={{ color: "white", width: 20, height: 20 }} />
                                            </div>
                                        </Button>
                                    </div> */}

                                    <div style={{ paddingLeft: 10 }}>
                                        <Button
                                            type="primary"
                                            style={{ width: 38 }}
                                            size="middle"
                                            onClick={(e) => {
                                                window.open("https://page.line.me/kingdomstore?openQrModal=true")
                                            }}
                                        >
                                            <div style={{ marginTop: 3, marginLeft: -7 }} >
                                                <Icon icon="bi:line" style={{ color: "white", width: 20, height: 20 }} />
                                            </div>
                                        </Button>
                                    </div>

                                    {/* <div style={{ paddingLeft: 10 }}>
                                        <Button
                                            type="primary"
                                            style={{ width: 38 }}
                                            size="middle"
                                            onClick={(e) => {
                                                window.open("mailto:kingdomstore888@gmail.com")
                                            }}
                                        >
                                            <div style={{ marginTop: 3, marginLeft: -7 }} >
                                                <Icon icon="clarity:email-solid" style={{ color: "white", width: 20, height: 20 }} />
                                            </div>
                                        </Button>
                                    </div> */}
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} md={12} xl={8}>
                            <div style={{ paddingRight: (width > 1200 ? 0 : 24), paddingLeft: (width > 767 ? 0 : 24) }} >
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.336152628297!2d100.56246527430044!3d13.758590786633954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29fb58af2a8ad%3A0x6ff6f0b258f4dc62!2zS2luZ2RvbSBzdG9yZSDguKPguYnguLLguJnguYLguJfguKPguKjguLHguJ7guJfguYzguKHguLfguK3guJbguLfguK0g4LiL4Li34LmJ4LitLeC4guC4suC4oi3guYDguJfguLTguKPguYzguJkt4LiL4LmI4Lit4LihLeC4iOC4s-C4meC4s-C5guC4l-C4o-C4qOC4seC4nuC4l-C5jOC4oeC4t-C4reC4luC4t-C4rQ!5e0!3m2!1sth!2sth!4v1715681977585!5m2!1sth!2sth"
                                    style={{ border: 0, width: "100%", height: 300 }}
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </Col>
                    </Row>
                </nav>
            </div>

            <nav style={{ backgroundColor: "#FF9900", color: "white", paddingLeft: '5%', paddingRight: '5%', paddingTop: 15, paddingBottom: 15, fontWeight: 600, textAlign: 'center' }}>
                <label>Copyright © 2024  Kingdom Store All Right Reserved.</label>
            </nav>

            <Modal
                title="ไลน์"
                visible={isModalLineVisible}
                onCancel={handleModalLine}
                // width={1000}
                footer={[]}
            >
                <img
                    style={{ objectFit: "cover", width: "100%", height: 500, borderRadius: 8 }}
                    src={`./assets/images/contact/line-qrcode.jpg`}
                />
            </Modal>
        </footer>
    )
}

export default Footer
