/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import {
    Row,
    Col,
    Input,
    Button,
    Form
} from 'antd'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification';
import { insertContactFetch } from '../../API';
import { detectEmail, detectPhone } from '../../../../common/mamager/DetectManager';

const { TextArea } = Input;

export default function ContactForm() {
    const { width } = useDimensions()

    const [form] = Form.useForm()

    const accessToken = sessionStorage.getItem("accessToken")

    const onFinish = async (values) => {
        let body = {
            "name": values.name ? values.name : "",
            "mobilePhone": values.mobilePhone ? values.mobilePhone : "",
            "email": values.email ? values.email : "",
            "description": values.description ? values.description : ""
        }
        const result = await insertContactFetch(null, body, accessToken)
        // console.log("boinsertContactFetchdy : ", result, body)
        if (result.isSuccess) {
            Notification('success', 'สร้างสำเร็จ')
            form.resetFields()
        } else {
            Notification('error', 'ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    return (
        <div
            style={{
                backgroundColor: "#ECECEC",
                backgroundSize: (width > 760 ? '100%' : '255%'),
                width: '100% auto',
                height: '100% auto',
                display: "grid",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <div
                style={{
                    paddingTop: 84,
                    paddingBottom: 84,
                    paddingLeft: (width > 1200 ? 0 : 24),
                    paddingRight: (width > 1200 ? 0 : 24),
                    maxWidth: 1000
                }}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24} style={{ textAlign: "center", paddingBottom: 12 }}>
                            <label style={{ fontSize: 26 }}>ติดต่อเรา</label>
                        </Col>

                        <Col xs={24} md={24} xl={24}>
                            <Form.Item
                                name="name"
                                // label=""
                                rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 1, maxRows: 1 }}
                                    placeholder="ชื่อ"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="mobilePhone"
                                // label=""
                                rules={[{ required: true, validator: detectPhone }]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 1, maxRows: 1 }}
                                    placeholder="เบอร์โทรศัพท์"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="email"
                                // label=""
                                rules={[{ required: true, validator: detectEmail }]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 1, maxRows: 1 }}
                                    placeholder="E-mail"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24} xl={24}>
                            <Form.Item
                                name="description"
                                // label=""
                                rules={[{ required: true, message: 'กรุณากรอกข้อความสอบถาม' }]}
                            >
                                <TextArea
                                    autoSize={{ minRows: 6, maxRows: 12 }}
                                    placeholder="ข้อความสอบถาม"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24} xl={24}>
                            <center>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        form.submit()
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="ant-design:message-outlined" style={{ color: "white", width: 19, height: 19 }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }} >ส่งข้อความ</label>
                                    </div>
                                </Button>
                            </center>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}