/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import { useDimensions } from '../../common/mamager/ScreenManager'
import { useLocation } from 'react-router'
import MobilePurchase from '../mobilePurchase'
import EstimatePrice from '../estimatePrice'

export default function Home() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const menu = params.get("menu")

    const { width } = useDimensions()

    return (
        <>
            <EstimatePrice/>
        </>
    )
}
