/* eslint-disable react-hooks/exhaustive-deps */
// 
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { Card, Col, Pagination, Rate, Row } from 'antd'
import { getReviewFetch } from '../../API/estimatePriceApi'

export default function Review() {

    const { width } = useDimensions()

    const pageCurrentRef = useRef(1)
    const [pageSize, setPageSize] = useState(3)
    const [total, setTotal] = useState(0)
    // const [page, setPage] = useState({
    //     minValue: 0,
    //     maxValue: 3
    // })

    // const [review, setReview] = useState([
    //     { title: "user 0001", description: "xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx", imgUrl: "./assets/images/default/df-img.png" },
    //     { title: "user 0002", description: "xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx", imgUrl: "./assets/images/default/df-img.png" },
    //     { title: "user 0003", description: "xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx", imgUrl: "./assets/images/default/df-img.png" },
    //     { title: "user 0004", description: "xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx", imgUrl: "./assets/images/default/df-img.png" },
    //     { title: "user 0005", description: "xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx xxxx", imgUrl: "./assets/images/default/df-img.png" }
    // ])

    const handleChange = async (value) => {
        // console.log(value)
        pageCurrentRef.current = value
        await getReview(value)
    }

    const [review, setReview] = useState([])

    const getReview = async (page) => {
        let param = {
            page: page,
            perPage: pageSize
        }
        const result = await getReviewFetch(param, null, null)
        // console.log("getReviewFetch : ", result)
        if (result?.status === 200) {
            setTotal(result?.metadata?.totalItem)
            setReview(result?.result)
        }
    }

    const getBaseApi = async () => {
        await getReview(pageCurrentRef.current)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <div
            style={{
                display: "grid",
                justifyContent: "center",
                backgroundColor: "#EEEEEE"
            }}
        >
            <div
                style={{
                    paddingTop: 82,
                    paddingBottom: 82,
                    paddingLeft: (width > 1200 ? 0 : 24),
                    paddingRight: (width > 1200 ? 0 : 24),
                    maxWidth: 1000
                }}
            >
                <div style={{ textAlign: "center", paddingBottom: 30 }}>
                    <label className='ks-title'>รีวิวความประทับใจจากลูกค้า</label>
                </div>

                <Row gutter={[24, 24]}>
                    {review?.map((val, index) => {
                        return (
                            <Col key={index} xs={24} sm={12} md={8} lg={8}>
                                <Card hoverable>
                                    <div style={{ paddingBottom: 10, display: "flex", alignItems: "center", minHeight: 72 }}>
                                        <img src={val?.ReviewCover?.googleImage} style={{ width: 60, objectFit: 'cover' }} />
                                        <label style={{ fontSize: 24, paddingLeft: 12 }}>{val.name}</label>
                                    </div>

                                    <div style={{ paddingBottom: 15 }}>
                                        <Rate disabled defaultValue={val.score} />
                                    </div>

                                    {/* <div style={{ display: "flex", justifyContent: "center", paddingBottom: 8 }}>
                                        <div style={{}}>
                                            <img src={val.imgUrl} style={{ width: "100%", height: 320, objectFit: 'cover' }} />
                                        </div>
                                    </div> */}

                                    <div style={{ minHeight: 130 }}>
                                        <label className='ks-item-detail'>{val.description}</label>
                                    </div>
                                </Card>
                            </Col>
                        )
                    })}

                    <Col span={24}>
                        <center>
                            <Pagination
                                defaultCurrent={pageCurrentRef.current}
                                defaultPageSize={pageSize}
                                onChange={handleChange}
                                total={total}
                            />
                        </center>
                    </Col>
                </Row>
            </div>
        </div>
    )
}