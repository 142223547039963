/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import {
    Row,
    Col,
    Button
} from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { scroller } from "react-scroll"

export default function Main() {

    const { width } = useDimensions()

    return (
        <>
            <div
                style={{
                    backgroundImage: width > 760 ? "url(./assets/images/background/img-bg.png)" : "url(./assets/images/background/img-bg-sm.png)",
                    backgroundSize: (width > 760 ? '100%' : '110%'),
                    width: '100% auto',
                    height: '100% auto',
                    filter: "brightness(110%)",
                    display: "grid",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        display: width > 760 ? "flex" : "grid",
                        justifyContent: width > 760 ? "space-between" : "center",
                        paddingTop: width > 1200 ? 150 : (width > 760 && width < 1200 ? 150 : 150),
                        paddingBottom: 82,
                        paddingLeft: (width > 1200 ? 0 : 24),
                        paddingRight: (width > 1200 ? 0 : 24),
                        maxWidth: 1000
                    }}
                >
                    <div
                        style={{
                            // display: "grid",
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                            borderRadius: 16,
                            padding: 24,
                            // height: "80%"
                        }}
                    >
                        <div style={{ display: width > 645 ? "flex" : "grid", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <div
                                style={{
                                    // paddingLeft: (width > 645 ? 24 : 0),
                                    // paddingTop: (width > 645 ? 0 : 24),
                                    display: "grid"
                                }}
                            >
                                <label style={{ fontSize: (width > 645 ? 26 : 24), fontWeight: 600 }}>
                                    รับซื้อโทรศัพท์ มือถือทุกรุ่น
                                </label>
                                <label style={{ fontSize: 22 }}>
                                    iPhone iPad Tablet MacBook Notebook ให้ราคาสูง
                                </label>
                            </div>
                        </div>
                    </div>

                    <div>
                        <img
                            src={"./assets/images/devices/dv-01.png"}
                            style={{ width: (width > 760 ? 400 : "100%"), borderRadius: 16, paddingLeft: width > 760 ? 24 : 0, paddingTop: width > 760 ? 0 : 38 }}
                            alt="Group Smartphone iPad Show"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}