import './fonts.css'

const themeAntd = {
    colorPrimary: "#FF9900",
    fontSize: 16,
    fontFamily: "Kanit",

    rateStarColor: "white", // สีของดาวที่ยังไม่ได้เลือก
    rateStarSelectedColor: "#FF9900", // สีของดาวที่ถูกเลือก
    rateStarSelectedBg: "transparent", // สีพื้นหลังของดาวที่ถูกเลือก
}

export default themeAntd