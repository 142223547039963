/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import {
    Row,
    Col,
    Modal,
    Steps,
    Button
} from 'antd'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

import { Icon } from '@iconify/react'

const { Step } = Steps

export default function ContactSales() {
    const { width } = useDimensions()

    // const [step, setStep] = useState(0)

    const [isModalLineVisible, setIsModalLineVisible] = useState(false)

    const handleModalLine = () => {
        setIsModalLineVisible(false)
    }

    return (
        <div
            style={{
                backgroundImage: width > 767 ? "url(./assets/images/background/bg-contact.jpg)" : "url(./assets/images/background/bg-contact-sm.jpg)",
                backgroundSize: (width > 760 ? '100%' : '255%'),
                width: '100% auto',
                height: '100% auto',
                display: "grid",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <div
                style={{
                    paddingTop: 84,
                    paddingBottom: 84,
                    paddingLeft: (width > 1200 ? 0 : 24),
                    paddingRight: (width > 1200 ? 0 : 24),
                    maxWidth: 1000,
                }}
            >
                <Row gutter={[24, 32]}>
                    <Col span={24} style={{}}>
                        <label style={{ fontSize: 26 }}>วิธีฝากเครื่องกับเราง่าย ๆ</label>
                    </Col>

                    <Col xs={24} md={width > 767 ? 14 : 24} xl={width > 767 ? 16 : 24}>
                        <div style={{ display: 'grid', alignItems: 'center' }}>
                            <Steps progressDot direction="vertical" current={4}>s
                                <Step
                                    title={"ขั้นตอนที่ 1"}
                                    description={
                                        <div style={{ display: "grid", }}>
                                            <label>ติดต่อทางร้านผ่านทางโทรศัพท์หรือ LINE แจ้งรายละเอียดข้อมูลของสินค้า รุ่น สเปคเครื่อง ที่ลูกค้าจะฝากกับทางเรา</label>

                                            <div style={{ paddingTop: 12, display: "flex" }}>
                                                <a href="tel:+66909243388">
                                                    <Button
                                                        type="primary"
                                                        // style={{ width: 45 }}
                                                        size="middle"
                                                    // onClick={(e) => {  }}
                                                    >
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} >
                                                            <Icon icon="carbon:phone-filled" style={{ color: "white", width: 19, height: 19 }} />
                                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>090-924-3388</label>
                                                        </div>
                                                    </Button>
                                                </a>

                                                <div style={{ paddingLeft: 10 }}>
                                                    <Button
                                                        type="primary"
                                                        style={{ width: 38 }}
                                                        size="middle"
                                                        onClick={(e) => {
                                                            // e.preventDefault()
                                                            // setIsModalLineVisible(true)
                                                            window.open("https://page.line.me/kingdomstore?openQrModal=true")
                                                        }}
                                                    >
                                                        <div style={{ marginTop: 3, marginLeft: -7 }} >
                                                            <Icon icon="bi:line" style={{ color: "white", width: 20, height: 20 }} />
                                                        </div>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />

                                <Step
                                    title={"ขั้นตอนที่ 2"}
                                    description={"ทางร้านประเมินราคาหรือลูกค้าเสนอราคาที่ลูกค้าอยากได้กับทางร้าน"}
                                />

                                <Step
                                    title={"ขั้นตอนที่ 3"}
                                    description={"ทางร้านเช็คเครื่องและออกบัตรฝากให้กับลูกค้าและรับเงินสดทันที"}
                                />
                            </Steps>
                        </div>

                        <div style={{ display: "grid", paddingTop: 24 }}>
                            <label style={{ fontWeight: 600 }}>
                                * ทางร้านไม่เน้นให้สินค้าหลุด
                            </label>

                            <label style={{ paddingTop: 6 }}>
                                เมื่อถึงวันครบกำหนดทางร้านจะทำการโทรแจ้งให้กับลูกค้าเสมอ กรณีลูกค้าไม่สะดวกนำเครื่องออกตามวันที่ครบกำหนดลูกค้าสามารถต่อดอกกับทางเราได้ก่อน ผ่านทางช่องทาง LINE ของเราได้เลย
                            </label>
                        </div>
                    </Col>

                    {/* {width > 767 ? */}
                        <Col xs={24} md={10} xl={8}>
                            <label style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                <img
                                    style={{ objectFit: "cover", width: "100%", borderRadius: 8 }}
                                    src={`./assets/images/about_us/store.jpg`}
                                />
                            </label>
                        </Col>
                        {/* : []
                    } */}
                </Row>

                <Modal
                    title="ไลน์"
                    visible={isModalLineVisible}
                    onCancel={handleModalLine}
                    // width={1000}
                    footer={[]}
                >
                    <img
                        style={{ objectFit: "cover", width: "100%", height: 500, borderRadius: 8 }}
                        src={`./assets/images/contact/line-qrcode.jpg`}
                    />
                </Modal>
            </div>
        </div>
    )
}
