/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Autoplay, A11y } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
// import 'swiper/css/scrollbar'
import "./css/index.css"

export default function Carouse(props) {
    // console.log("props.data : ", props.data)
    return (
        <div>
            {props.data?.length > 0 ?
                <Swiper
                    modules={[
                        Navigation, 
                        Pagination, 
                        A11y, 
                        Autoplay
                    ]}
                    spaceBetween={12}
                    slidesPerView={"auto"}
                    navigation
                    pagination={{ clickable: true }}
                    // scrollbar={{ draggable: true }}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                >
                    {props.data.map((val, index) =>
                        <SwiperSlide key={index}>
                                <img
                                    src={`${val?.image}`}
                                    alt={val?.title ?? ""}
                                    style={{
                                        width: "100%",
                                        height: 300,
                                        borderRadius: 15,
                                        border: "1px solid #FF9900"
                                    }}
                                />
                                <meta itemprop="name" content={val?.title ?? ""} />
                                <meta itemprop="keywords" content={val?.title ?? ""} />
                        </SwiperSlide>
                    )}
                </Swiper>
                : []
            }
        </div>
    )
}