/* eslint-disable react-hooks/exhaustive-deps */

import {
    Row,
    Col
} from 'antd'
import Header from '../../common/components/header'
import Footer from '../../common/components/footer'
import Main from './components/main'
import EasyToSell from './components/easyToSell'
import SoldAtKingdomStore from './components/soldAtKingdomStore'
import BrandHilight from './components/brandHilight'
import Review from './components/review'

export default function EstimatePrice() {
    return (
        <>
            <Header />

            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Row>
                    <Col span={24}>
                        <Main />
                    </Col>

                    <Col span={24}>
                        <EasyToSell />
                    </Col>

                    <Col span={24}>
                        <SoldAtKingdomStore />
                    </Col>

                    <Col span={24}>
                        <BrandHilight />
                    </Col>

                    <Col span={24}>
                        <Review />
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )
}