import { server } from "../../../constants"
import { HttpClient2 } from "../../../utils/HttpClient2"

const getProvinceFetch = async (data) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_PROVINCE_URL, data, {
            headers: {
                'Accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getDistrictFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_DISTRICT_URL + `?provinceId=${param.id}`, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getTrainLineFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_TRAIN_LINE_URL, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getTrainStationFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.get(server.GET_TRAIN_STATION_URL + `?trainCategoriesId=${param.trainCategoriesId}`, {
            headers: {
                'accept': 'application/json'
            }
        })
        return result.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const insertSaleInfoFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await HttpClient2.post(server.INSERT_SALE_INFO_URL, body, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getProvinceFetch,
    getDistrictFetch,
    getTrainLineFetch,
    getTrainStationFetch,

    // insert
    insertSaleInfoFetch,

    // update

    // delete
}