import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

// Contact
const getContactFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.get(server.GET_CONTACTS_URL + `?name=${param.name}&isActive=${param.isActive}&page=${param.page}&size=${param.size}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateContactFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.put(server.UPDATE_CONTACTS_URL + `/${param.id}`, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getContactFetch,

    // update
    updateContactFetch,
}
